import { Button } from '@mui/material';
import React, { useState } from 'react'
import { usePaystackPayment } from "react-paystack";
import { useSelector } from 'react-redux';
import { t } from "i18next";
import api from "../API/Fetch_data_Api";
import { toast } from "react-toastify";
// import { useTheme } from "@emotion/react";

const Paystack = ({ amount }) => {

    const payment_gateway = useSelector(state => state.Settings)?.settings?.payment_gateways_settings;
    const user_details = useSelector(state => state.UserData)?.profile;



    const date = localStorage.getItem("selectedDate");
    const time = localStorage.getItem("selectedSlot");

    const address_id = localStorage.getItem("selectedAddres");
    const DeliveryMethod = localStorage.getItem("DeliveryMethod");
    const address = address_id != "" ? JSON.parse(address_id) : "";

    let order_note = localStorage.getItem("orderNote");

    const [order_id, setOrderID] = useState(0);

    const config = {
        reference: (new Date()).getTime().toString(),
        currency: "NGN",
        email: user_details.email,
        amount: amount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: payment_gateway.paystack_key,
    };

    // you can call this function anything

    const initializePayment = usePaystackPayment(config);


    const handlePayout = () => {

        api
            .plaecOrder("paystack", "awaiting", "", date, time, DeliveryMethod, DeliveryMethod == "Home" ? address?.id : "", order_note).then(result => {
                console.log(result);
                if (result.error === false) {
                    setOrderID(result.data.order_id);

                    const onSuccess = (reference) => {
                        // Implementation for whatever you want to do with reference and after success call.
                        console.log(reference);
                        api.add_transactions(result.data.order_id, "success").then(result => {
                            if (result.error === true) {
                                toast.error(result.message);
                            } else {
                                toast.success("Service Booked successfully");
                            }
                        })
                            .then(res => {
                                localStorage.removeItem("cart");
                                localStorage.removeItem("orderNote");
                                localStorage.removeItem("CartDetails");
                                localStorage.removeItem("cartItems");
                                localStorage.removeItem("selectedSlot");
                                localStorage.removeItem("selectedDate");
                                localStorage.removeItem("selectedAddres");
                            })
                            .then(res => {
                                window.location.reload();
                            });
                    };

                    // you can call this function anything
                    const onClose = () => {
                        // implementation for  whatever you want to do when the Paystack dialog closed.
                        console.log('closed')
                        api.add_transactions(order_id, 'cancelled').then((res) => {
                        });
                    }

                    initializePayment(onSuccess, onClose)


                } else {
                    toast.error(result.message);
                }
            });

    }



    return (
        <Button fullWidth sx={{ my: 3 }} onClick={() => {
            handlePayout();
            // initializePayment(onSuccess, onClose)
        }}>
            {t("make_a_payment")}
        </Button>
    )
}

export default Paystack