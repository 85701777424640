import cart from "./cart";
import Settings from "./Settings";
import Pages from "./Pages";
import Location from "./Location";
import Bookmark from "./Bookmark";
import Promocode from "./Promocode";
import UserData from "./UserData";
import authentication from "./authentication";
import Provider from "./Provider";
import UserAddress from "./UserAddress";
import Categories from "./Categories";
import Login from "./Login";
import Token from "./Token";
import DeliveryAddress from "./DeliveryAddress";

const rootReducer = {
  authentication,
  cart,
  Settings,
  Pages,
  Location,
  Bookmark,
  Promocode,
  UserData,
  Provider,
  UserAddress,
  Categories,
  Login,
  Token,
  DeliveryAddress,
};

export default rootReducer;
