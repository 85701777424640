// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

const initialValue = localStorage.getItem("selectedAddres");

export const deliverySlice = createSlice({
  name: "delivery",
  initialState: {
    delivery: initialValue == null ? [] : initialValue,
  },
  reducers: {
    setDeliveryAddress: (state, action) => {
      state.delivery = action.payload;
      localStorage.setItem("selectedAddres", action.payload);
    },
  },
});

export const { setDeliveryAddress } = deliverySlice.actions;

export default deliverySlice.reducer;
